let appEnv = process.env.VUE_APP_ENV;

let appBackendUrl = "";
let appBackendMpiteSanUrl = "";
let appBackendBPpayUrl = "";
let appBackendOnePayUrl = "";
let appBackendCitizenPayUrl = "";
let horoUrl = "";
let citizenpayCheckPaymentStatus = "";
let oneYearBaydinUrl = "";

if (appEnv === "local") {
    appBackendUrl = process.env.VUE_APP_LOCAL_BACKEND_URL;
    appBackendMpiteSanUrl = process.env.VUE_APP_LOCAL_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_LOCAL_BACKEND_ONEPAY_URL;
    appBackendCitizenPayUrl = process.env.VUE_APP_LOCAL_BACKEND_CITIZENPAY_URL;
    horoUrl = process.env.VUE_APP_LOCAL_HORO_URL;
    citizenpayCheckPaymentStatus =
        process.env.VUE_APP_LOCAL_CITIZENPAY_PAYMENTSTATUS_URL;
    oneYearBaydinUrl = process.env.VUE_APP_LOCAL_ONE_YEAR_BAYDIN_URL;
} else if (appEnv === "staging") {
    appBackendUrl = process.env.VUE_APP_STAGING_BACKEND_URL;
    appBackendMpiteSanUrl = process.env.VUE_APP_STAGING_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_STAGING_BACKEND_ONEPAY_URL;
    appBackendCitizenPayUrl =
        process.env.VUE_APP_STAGING_BACKEND_CITIZENPAY_URL;
    horoUrl = process.env.VUE_APP_STAGING_HORO_URL;
    citizenpayCheckPaymentStatus =
        process.env.VUE_APP_STAGING_CITIZENPAY_PAYMENTSTATUS_URL;
    oneYearBaydinUrl = process.env.VUE_APP_STAGING_ONE_YEAR_BAYDIN_URL;
} else if (appEnv === "production") {
    appBackendUrl = process.env.VUE_APP_PRODUCTION_BACKEND_URL;
    appBackendMpiteSanUrl = process.env.VUE_APP_PRODUCTION_BACKEND_MPITESAN_URL;
    appBackendBPpayUrl = process.env.VUE_APP_PRODUCTION_BACKEND_BPPAY_URL;
    appBackendOnePayUrl = process.env.VUE_APP_PRODUCTION_BACKEND_ONEPAY_URL;
    appBackendCitizenPayUrl =
        process.env.VUE_APP_PRODUCTION_BACKEND_CITIZENPAY_URL;
    horoUrl = process.env.VUE_APP_PRODUCTION_HORO_URL;
    citizenpayCheckPaymentStatus =
        process.env.VUE_APP_PRODUCTION_CITIZENPAY_PAYMENTSTATUS_URL;
    oneYearBaydinUrl = process.env.VUE_APP_PRODUCTION_ONE_YEAR_BAYDIN_URL;
} else {
    appBackendUrl = process.env.VUE_APP_PRODUCTION_BACKEND_URL;
    appBackendBPpayUrl = process.env.VUE_APP_PRODUCTION_BACKEND_BPPAY_URL;
    appBackendMpiteSanUrl = process.env.VUE_APP_LOCAL_BACKEND_MPITESAN_URL;
    appBackendOnePayUrl = process.env.VUE_APP_LOCAL_BACKEND_ONEPAY_URL;
    appBackendCitizenPayUrl = process.env.VUE_APP_LOCAL_BACKEND_CITIZENPAY_URL;
    horoUrl = process.env.VUE_APP_LOCAL_HORO_URL;
    citizenpayCheckPaymentStatus =
        process.env.VUE_APP_LOCAL_CITIZENPAY_PAYMENTSTATUS_URL;
    oneYearBaydinUrl = process.env.VUE_APP_PRODUCTION_ONE_YEAR_BAYDIN_URL;
}

appBackendBPpayUrl = process.env.VUE_APP_PRODUCTION_BACKEND_BPPAY_URL;

let paymentPortalV3URL = process.env.VUE_APP_BPPAY_V3_URL;
let paymentPortalURL = process.env.VUE_APP_PAYMENT_PORTAL_URL;

let bpPayMpitesanMerchantId = process.env.VUE_APP_BP_PAY_MERCHANT_ID;
let bpPayMpitesanServiceName = process.env.VUE_APP_BP_PAY_SERVICE_NAME;
let bpPayMpitesanEmail = process.env.VUE_APP_BP_PAY_EMAIL;
let bpPayMpitesanPassword = process.env.VUE_APP_BP_PAY_PASSWORD;
let bpPayMpitesanKey = process.env.VUE_APP_BP_PAY_KEY;

let bpPayAllMerchantId = process.env.VUE_APP_ALL_PAY_BP_MERCHANT_ID;
let bpPayAllServiceName = process.env.VUE_APP_ALL_PAY_BP_SERVICE_NAME;
let bpPayAllEmail = process.env.VUE_APP_ALL_PAY_BP_EMAIL;
let bpPayAllPassword = process.env.VUE_APP_ALL_PAY_BP_PASSWORD;
let bpPayAllKey = process.env.VUE_APP_ALL_PAY_BP_KEY;

export default {
    appBackendUrl,
    appBackendBPpayUrl,
    citizenpayCheckPaymentStatus,
    appBackendMpiteSanUrl,
    appBackendOnePayUrl,
    appBackendCitizenPayUrl,
    horoUrl,
    paymentPortalV3URL,
    paymentPortalURL,
    oneYearBaydinUrl,

    bpPayMpitesanMerchantId,
    bpPayMpitesanServiceName,
    bpPayMpitesanEmail,
    bpPayMpitesanPassword,
    bpPayMpitesanKey,

    bpPayAllMerchantId,
    bpPayAllServiceName,
    bpPayAllEmail,
    bpPayAllPassword,
    bpPayAllKey
};
